import moment from 'moment'
import EventBus from '@/util/EventBus'
import { Billing } from '@/models/account/Billing.js'
import { ChannelType } from '@/models/ChannelType'
import { WhatsappGraphicData } from '@/classes/stats/WhatsappGraphicData'
import RssFeedService from '@/services/rss-feed.service'
import AccountService from '@/services/account.service.js'
import VerifiedSmsService from '@/services/verified-sms.service'
import CampaignService from '@/services/campaign.service'
import HeaderTopDashboard from '@/components/Layout/HeaderTopDashboard.vue'
import GeneralStats from '@/components/Graphs/GeneralStats/GeneralStats.vue'
import DeliveredSms from '@/components/Stats/Sms/DeliveredSms.vue'
import UserInteractionSms from '@/components/Stats/Sms/UserInteractionSms.vue'
import UserInteractionSmsRate from '@/components/Stats/Sms/UserInteractionSmsRate.vue'
import UserInteractionVoiceRate from '@/components/Stats/Voice/UserInteractionVoiceRate.vue'
import DeliveredMailing from '@/components/Stats/Mailing/DeliveredMailing.vue'
import UserInteractionMailing from '@/components/Stats/Mailing/UserInteractionMailing.vue'
import UserInteractionMailingRate from '@/components/Stats/Mailing/UserInteractionMailingRate.vue'
import DeliveredVoice from '@/components/Stats/Voice/DeliveredVoice.vue'
import UnsubscribedSms from '@/components/Stats/Sms/UnsubscribedSms.vue'
import UnsubscribedMailing from '@/components/Stats/Mailing/UnsubscribedMailing.vue'
import UnsubscribedVoice from '@/components/Stats/Voice/UnsubscribedVoice.vue'
import DeliveredWhatsapp from '@/components/Stats/Whatsapp/DeliveredWhatsapp.vue'
import UnsubscribedWhatsapp from '@/components/Stats/Whatsapp/UnsubscribedWhatsapp.vue'
import ImpactCostVoice from '@/components/Stats/Voice/ImpactCostVoice.vue'
import HomeModalAction from './HomeModalAction/HomeModalAction.vue'
import StatsService from '@/services/stats.service'
import VoiceStatsService from '@/services/voice-stats.service'
import OverlayManager from '@/components/OverlayManager/OverlayManager.vue'
import { Colors } from '@/classes/colors/Colors'
import HomeModalCampaignValidate from './HomeModalCampaignValidate/HomeModalCampaignValidate.vue'
import HomeModalAi from './HomeModalAi/HomeModalAi.vue'
import HomeModalBillingProfile from './HomeModalBillingProfile/HomeModalBillingProfile.vue'
import { Languages } from '@/classes/Languages'
import { SendingStatuses } from '@/models/SendingStatuses'
import userService from '@/services/user.service'
import ContactService from '@/services/contacts.service.js'
import { RatingAppLauncher } from '@/classes/RatingAppLauncher'
import { sha256 } from '@/util/SHA256'
import DltInfoDialog from '../../Pages/Tools/DLT/DltInfoDialog/DltInfoDialog.vue'
import { Country } from '@/util/Country.js'
import getEnv from '@/util/env'
import RadialBarWithLegend from '@/components/Stats/RadialBar/RadialBarWithLegend.vue'
import RadialBar from '@/components/Stats/RadialBar/RadialBar.vue'

const VUE_APP_OLD_DASHBOARD_URL = getEnv('VUE_APP_OLD_DASHBOARD_URL')

export default {
  name: 'Home',
  components: {
    HeaderTopDashboard,
    GeneralStats,
    DeliveredSms,
    DeliveredMailing,
    DeliveredVoice,
    UnsubscribedSms,
    UnsubscribedMailing,
    UnsubscribedVoice,
    HomeModalAction,
    UserInteractionSms,
    UserInteractionMailing,
    ImpactCostVoice,
    OverlayManager,
    HomeModalCampaignValidate,
    HomeModalAi,
    HomeModalBillingProfile,
    UserInteractionSmsRate,
    UserInteractionMailingRate,
    UserInteractionVoiceRate,
    DltInfoDialog,
    DeliveredWhatsapp,
    UnsubscribedWhatsapp,
    RadialBarWithLegend,
    RadialBar,
  },
  data: function () {
    return {
      refreshDatatableFrecuency: 10000,
      loading: true,
      disabledPagination: false,
      options: {},
      statuses: [],
      content: [],
      searchTerm: '',
      itemsPerPage: 10,
      pageCount: 0,
      page: 1,
      eventItemsPerPage: 10,
      eventPageCount: 0,
      eventPage: 1,
      eventDayItems: [],
      totalVisibleCampaigns: 0,
      totalExistingCampaigns: 0,
      channelFilterSelected: null,
      statusesColors: Colors.statusesColors,
      reload: false,
      newsFeed: {},
      billing: new Billing(),
      googleVerified: [],
      balanceAlert: {},
      selectedCampaign: null,
      loadedFirstTime: false,
      ready: false,
      showPendingFiscalProfile: false,
      campaginDataSource: {},
      clickedDay: null,
      showDayDialog: false,
      type: 'month',
      types: ['month', 'week', 'day', '4day'],
      mode: 'stack',
      modes: ['stack', 'column'],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      weekdays: [
        { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
        { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
        { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
        { text: 'Mon, Wed, Fri', value: [1, 3, 5] },
      ],
      value: '',
      events: [],
      currentEvents: [],
      calendarEvents: [],
      commercialEvents: [],
      nearScheduledCampaigns: [],
      nearEvents: [],
      timeout: null,
      campaignStatusValidating: 15,
      campaignStatusValidated: 16,
      showHomeModalCampaignValidate: false,
      loadingHomeModalCampaignValidate: false,
      itemHomeModalCampaignValidate: null,
      showHomeModalAi: true,
      showAiProfile: false,
      showHomeModalBillingProfile: true,
      showBillingProfile: false,
      googleVerifiedAvailable: [
        'es',
        'us',
        'ca',
        'mx',
        'br',
        'uk',
        'fr',
        'it',
        'in',
        'ph',
      ],
      dltAvailable: [
        'in',
      ],
      showDltInfo: false,
      showRateApp: false,
      rates: [
        { point: 1, icon: 'fas fa-frown-open', color: 'red' },
        { point: 2, icon: 'fa fa-frown', color: 'orange' },
        { point: 3, icon: 'fas fa-meh', color: 'yellow' },
        { point: 4, icon: 'fa fa-smile', color: 'green' },
        { point: 5, icon: 'fas fa-smile-beam', color: 'green' },
      ],
      selectedRate: null,
      knowOpinionDialog: false,
      userOpinionRate: '',
      trustPilotDialog: false,
      ratedPoint: 0,
      showRating: false,
      smsQuickStats: {
        campaign: null,
        channel: null,
        tags: null,
      },
      mailingQuickStats: {
        campaign: null,
      },
      voiceQuickStats: {
        campaign: null,
      },
      whatsappQuickStats: {
        campaign: null,
        channel: null,
        tags: null,
      },
      campaignCountries: [],
      tableAction: '',
      contentHash: null,
      Country: Country,
      flashCampaign: null,
      user: this.$store.getters['auth/getUser'],
      deliveredRatioGraphic: null,
      clicksRatioGraphicData: null,
      ChannelType: ChannelType,
    }
  },
  watch: {
    params: {
      handler () {
        this.setParamsAndGetData()
      },
      deep: true,
    },
    locale: function () {
      this.setCommercialEvents()
      this.channelFilterSelected.title = this.channelsFilter.find(ch => ch.id === this.channelFilterSelected.id).title
    },
    searchTerm () {
      this.options.page = 1
      this.setParamsAndGetData()
    },
  },
  computed: {
    canWhatsapp () {
      return this.$store.getters['auth/getUser'].enable?.wa
    },
    headers () {
      return [
        { text: this.$t('Id'), align: 'start', value: 'campaignId' },
        {
          text: this.$t('Canal'),
          align: 'start',
          value: 'campaignName',
          sortable: false,
        },
        { text: this.$t('Campaña'), align: 'start', value: 'campaignName' },
        { text: this.$t('Envío'), align: 'center', value: 'created_at' },
        {
          text: this.$t('Actualización'),
          align: 'center',
          value: 'updated_at',
        },
        { text: this.$t('Enviados'), align: 'center', value: 'total' },
        { text: this.$t('Estado'), align: 'center', value: 'status_id' },
        {
          text: this.$t('Acciones'),
          align: 'center',
          value: 'actions',
          sortable: false,
        },
        {
          text: '',
          align: 'center',
          value: 'automaticActions',
          sortable: false,
        },
      ]
    },
    channelsFilter () {
      const channels = [
        { title: this.$t('Todos los canales'), id: -1, channels: [] },
        { title: 'SMS', id: 1, channels: [1] },
        { title: 'Mail', id: 2, channels: [2] },
        { title: this.$t('Voz'), id: 7, channels: [4, 7] },
      ]

      // <!-- App Preview Whatsapp -->
      // if (this.canWhatsapp) {
      //   channels.push({ title: this.$t('WhatsApp'), id: 8, channels: [8] })
      // }
      channels.push({ title: this.$t('WhatsApp'), id: 8, channels: [8] })

      return channels
    },
    eventDays () {
      return [
        {
          id: 'anyo-nuevo',
          start: '-01-01',
          name: this.$t('Año nuevo'),
          color: 'green',
        },
        {
          id: 'reyes-magos',
          start: '-01-06',
          name: this.$t('Día de los reyes magos'),
          color: 'green',
        },
        {
          id: 'rebajas-invierno',
          start: '-01-07',
          name: this.$t('Comienzo de rebajas (2 semanas)'),
          color: 'green',
        },
        {
          id: 'blue-monday',
          start: '-01-20',
          name: 'Blue Monday',
          color: 'green',
        },
        {
          id: 'san-valentin',
          start: '-02-14',
          name: this.$t('San valentín'),
          color: 'green',
        },
        {
          id: 'dia-mujer',
          start: '-03-08',
          name: this.$t('Día de la mujer'),
          color: 'green',
        },
        {
          id: 'dia-san-patricio',
          start: '-03-17',
          name: this.$t('St. Patrick\'s Day'),
          color: 'green',
        },
        {
          id: 'dia-padre',
          start: '-03-19',
          name: this.$t('Día del padre'),
          color: 'green',
        },
        {
          id: 'dia-primavera',
          start: '-03-20',
          name: this.$t('Empieza la Primavera (Hemisferio Norte)'),
          color: 'green',
        },
        {
          id: 'dia-ninyo',
          start: '-04-15',
          name: this.$t('Día del niño'),
          color: 'green',
        },
        {
          id: 'semana-santa',
          start: '-04-17',
          name: this.$t('Semana santa'),
          color: 'green',
        },
        {
          id: 'dia-libro',
          start: '-04-23',
          name: this.$t('Día del libro'),
          color: 'green',
        },
        {
          id: 'dia-trabajador',
          start: '-05-01',
          name: this.$t('Día del trabajador'),
          color: 'green',
        },
        {
          id: 'dia-madre',
          start: '-05-04',
          name: this.$t('Día de la madre'),
          color: 'green',
        },
        {
          id: 'yellow-day',
          start: '-06-20',
          name: this.$t('Yellow day'),
          color: 'green',
        },
        {
          id: 'empieza-verano',
          start: '-06-21',
          name: this.$t('Empieza el Verano (Hemisferio Norte)'),
          color: 'green',
        },
        {
          id: 'san-juan',
          start: '-06-23',
          name: this.$t('Noche de San Juan'),
          color: 'green',
        },
        {
          id: 'dia-orgullo',
          start: '-06-28',
          name: this.$t('Día del orgullo LGTBI'),
          color: 'green',
        },
        {
          id: 'dia-rrss',
          start: '-06-30',
          name: this.$t('Día de las redes sociales'),
          color: 'green',
        },
        {
          id: 'rebajas-verano',
          start: '-07-01',
          name: this.$t('Rebajas de verano'),
          color: 'green',
        },
        {
          id: 'vuelta-cole',
          start: '-09-09',
          name: this.$t('Vuelta al cole'),
          color: 'green',
        },
        {
          id: 'dia-otonyo',
          start: '-09-23',
          name: this.$t('Empieza el Otoño (Hemisferio Norte)'),
          color: 'green',
        },
        {
          id: 'dia-halloween',
          start: '-10-31',
          name: 'Halloween',
          color: 'green',
        },
        {
          id: 'dia-todos-santos',
          start: '-11-01',
          name: this.$t('Día de todos los santos'),
          color: 'green',
        },
        {
          id: 'dia-soltero',
          start: '-11-11',
          name: this.$t('Día del soltero'),
          color: 'green',
        },
        {
          id: 'black-friday',
          start: '-11-28',
          name: 'Black friday',
          color: 'green',
        },
        {
          id: 'cyber-monday',
          start: '-12-01',
          name: 'Cyber monday',
          color: 'green',
        },
        {
          id: 'dia-invierno',
          start: '-12-24',
          name: this.$t('Empieza el Invierno (Hemisferio Norte)'),
          color: 'green',
        },
        {
          id: 'noche-buena',
          start: '-12-24',
          name: this.$t('Noche buena'),
          color: 'green',
        },
        {
          id: 'dia-navidad',
          start: '-12-25',
          name: this.$t('Navidad'),
          color: 'green',
        },
        {
          id: 'noche-vieja',
          start: '-12-31',
          name: this.$t('Noche vieja'),
          color: 'green',
        },
      ]
    },
    usedActions () {
      return [
        {
          link: 'campaign/voice/create',
          name: this.$t('Envío VOZ').toString(),
          icon: 'fas fa-phone',
          highlighted: true,
        },
        {
          link: 'payments',
          name: this.$t('Añadir saldo').toString(),
          icon: 'fas fa-credit-card',
          highlighted: false,
        },
        {
          link: 'tools/contacts',
          name: this.$t('Mis contactos').toString(),
          icon: 'fas fa-address-book',
          highlighted: false,
        },
        {
          link: 'developers/plugin-and-integrations',
          name: this.$t('Plugins y módulos').toString(),
          icon: 'fas fa-puzzle-piece',
          highlighted: false,
        },
        {
          link: 'stats/sms',
          name: this.$t('Estadísticas SMS').toString(),
          icon: 'fas fa-chart-pie',
          highlighted: false,
        },
        {
          link: 'users/billing',
          name: this.$t('Mis facturas').toString(),
          icon: 'fas fa-file-invoice',
          highlighted: false,
        },
        {
          link: 'stats/audit',
          name: this.$t('Auditoría').toString(),
          icon: 'fas fa-stethoscope',
          highlighted: false,
        },
      ]
    },
    balance () {
      return this.$store.state.balance
    },
    calculatedBalance () {
      return this.balanceAlert.balance
    },
    hasPlan () {
      return this.balance.userPlan?.contact + this.balance.userPlan?.mailing > 0
    },
    params (nv) {
      return {
        ...this.options,
      }
    },
    locale () {
      return this.$i18n.locale
    },
    searchTermHelper: {
      get: function () {
        return this.debouncedInput
      },
      set: function (val) {
        this.debouncedInput = val
        if (this.timeout !== null) {
          clearTimeout(this.timeout)
        }
        this.timeout = setTimeout(() => {
          this.searchTerm = val
        }, 700)
      },
    },
    isPrepago: function () {
      const billingProfile = this.billing.billingProfiles[0]
      return billingProfile?.payment_type === 'prepago'
    },
    calendarLocale: function () {
      const iso = this.$i18n.locale.split('-')[0] || 'es'
      return Languages.get().find((l) => l.iso === iso).locale
    },
    isGoogleVerifiedAvailable () {
      const user = this.$store.getters['auth/getUser']
      if (
        this.googleVerifiedAvailable.includes(user.isoCountry?.toLowerCase() || user.isoTld?.toLowerCase())
      ) {
        return true
      }

      return false
    },
    isDltAvailable () {
      const user = this.$store.getters['auth/getUser']
      if (
        this.dltAvailable.includes(user.isoCountry?.toLowerCase() || user.isoTld?.toLowerCase())
      ) {
        return true
      }

      return false
    },
    hasLowBalanceAlert () {
      return parseInt(this.balanceAlert.low_balance) && (parseFloat(this.balance.amount) <= parseFloat(this.balanceAlert.low_balance_level))
    },
    hasTags () {
      return this.selectedStats?.tags?.length
    },
    selectedStats () {
      switch (this.selectedCampaign.channelTypeId) {
        case ChannelType.SMS_ID:
          return this.smsQuickStats
        case ChannelType.MAILING_ID:
          return this.mailingQuickStats
        case ChannelType.VOICE_INTERACTIVE_ID:
          return this.voiceQuickStats
        case ChannelType.WHATSAPP_ID:
          return this.whatsappQuickStats
      }
      return null
    },
    whatsappMessage () {
      return this.whatsappQuickStats?.channel && this.whatsappQuickStats.channel.message
    },
    whatsappChannelName () {
      return this.whatsappQuickStats?.campaign && this.whatsappQuickStats.campaign.typeName
    },
    whatsappSender () {
      return this.whatsappQuickStats && this.whatsappQuickStats.senderName
    },
  },
  created () {
    this.getUserBalance()

    // reloadTable
    EventBus.$on('reloadTable', (status) => {
      this.tableAction = status

      if (this.tableAction === 'DELETED') {
        this.closeDayDialog()
      }

      this.setParamsAndGetData()
    })
    this.channelFilterSelected = this.channelsFilter[0]

    EventBus.$on('rateApp', () => {
      this.showRateApp = true
    })

    EventBus.$on('flashCampaign', this.setFlashCampaign)
  },
  mounted () {
    this.reload = true
    this.loadNews()
    this.getBilling()
    this.loadGoogleVerifiedBrands()
    this.options.itemsPerPage = this.itemsPerPage
    this.setBalanceAlert()
    this.setCommercialEvents()
    this.setNearScheduledCampaigns()
    this.setParamsAndGetData()
    this.initIntervalCampainDataTable()
    this.loadFlashCampaign()
    this.loadShowProfiles()
  },
  unmounted () {
    EventBus.$off('flashCampaign', this.setFlashCampaign)
  },
  destroyed () {
    clearInterval(this.refresh)
  },
  methods: {
    goToOldDashboard () {
      AccountService.getAutoLoginToken().then((tokenData) => {
        window.location.href = VUE_APP_OLD_DASHBOARD_URL + 'autologin/' + tokenData.username + '/' + tokenData.token
      })
    },
    isDisabledSeeStatus (item) {
      // const cannotSeeStats = !this.statuses.statistics.includes(parseInt(item.status_id))
      const hasProcessedMessages = item.total_processed !== 0
      // const isAPI = item.statusGuid === 'API'
      // return !hasProcessedMessages || isAPI
      return !hasProcessedMessages
    },
    totalSentMessages (item) {
      if ([SendingStatuses.PENDING, SendingStatuses.SAVED, SendingStatuses.EDITING].includes(item.status_id)) {
        return item.total_recipients + item.total_test
      } else {
        return item.total
      }
    },
    itemRowClass (item) {
      if (item.dynamicClass) {
        return item.dynamicClass
      }
      return this.selectedCampaign && this.selectedCampaign.campaignId === item.campaignId ? 'selected-row' : ''
    },
    setFlashCampaign (campaign) {
      localStorage.setItem('flashCampaign', campaign.sendings[0].id)
    },
    loadFlashCampaign () {
      this.flashCampaign = +localStorage.getItem('flashCampaign') || null
      localStorage.removeItem('flashCampaign')
    },
    isNotApi (item) {
      return item.status_id !== SendingStatuses.API
    },

    closeRateAppDialog () {
      this.showRateApp = false
      this.rateAppOutSide()
    },

    rateAppOutSide () {
      RatingAppLauncher.refreshCounter()
    },

    changeUserShowRating (showRating) {
      const user = JSON.parse(localStorage.getItem('user'))
      user.showRating = showRating
      localStorage.setItem('user', JSON.stringify(user))
    },

    changeUserShowAiProfile (showAiProfile) {
      const user = JSON.parse(localStorage.getItem('user'))
      user.showAiProfile = showAiProfile
      localStorage.setItem('user', JSON.stringify(user))
    },

    setShowRatingByUser () {
      userService.setShowRatingByUser({ showRating: !this.showRating }).then(() => {
        this.showRateApp = false
        this.changeUserShowRating(!this.showRating)
      }, () => {
        EventBus.$emit('showAlert', 'danger', this.$t('Se produjo un error al cambiar el estado de mostrar valorar plataforma').toString())
      })
    },

    setShowRatingByUserToFalse () {
      userService.setShowRatingByUser({ showRating: false }).then(() => {
        this.changeUserShowRating(false)
       }, () => {
        EventBus.$emit('showAlert', 'danger', this.$t('Se produjo un error al cambiar el estado de mostrar valorar plataforma').toString())
      })
    },

    setShowAiProfileByUser () {
      userService.setShowAiProfileByUser({ showAiProfile: !this.showHomeModalAi }).then(() => {
        this.changeUserShowAiProfile(!this.showHomeModalAi)
        this.showHomeModalAi = false
      }, () => {
        EventBus.$emit('showAlert', 'danger', this.$t('Se produjo un error al cerrar el dialogo').toString())
      })
    },

    loadShowProfiles () {
      const user = JSON.parse(localStorage.getItem('user'))
      this.showAiProfile = user.showAiProfile
      this.showBillingProfile = user.showBillingProfile
    },

    sendOpinion () {
      userService.sendOpinion({ point: this.selectedRate.point, opinion: this.userOpinionRate }).then(() => {
        this.knowOpinionDialog = false
        EventBus.$emit('showAlert', 'success', this.$t('Se ha enviado tu opinión').toString())
      }, () => {
        EventBus.$emit('showAlert', 'danger', this.$t('Se produjo un error al enviar tu opinión').toString())
      })
    },

    rateApp (rate) {
      this.selectedRate = rate
      this.showRateApp = false
       setTimeout(() => {
        if (rate.point > 3) {
          this.trustPilotDialog = true
        } else {
          this.knowOpinionDialog = true
        }
        this.setShowRatingByUserToFalse()
        }, 300)
    },

    goToEditCampaign (event) {
      let url = ''
      switch (event.channel_type_id) {
        case 1:
          url = '/campaign/sms/edit/' + event.id
          break
        case 2:
          url = '/campaign/mailing/edit/' + event.id
          break
        case 3:
          url = '/campaign/voice/edit/' + event.id
      }
      this.$router.push(url)
    },

    formattDate (date) {
      return moment(date).format('DD-MM-YYYY')
    },

    getMainHtmlElement () {
      return document.getElementsByTagName('html')[0]
    },

    showCalendar () {
      const htmlElement = this.getMainHtmlElement()
      htmlElement.style.overflow = 'hidden'
      EventBus.$emit('showOverlayCalendar')
    },

    closeCalendar () {
      const htmlElement = this.getMainHtmlElement()
      htmlElement.style.overflow = 'scroll'
    },

    setCommercialEvents () {
      const commercialEvents = []
      const currentYear = new Date().getFullYear()
      this.eventDays.forEach((eventDay) => {
        const event = Object.assign({}, eventDay)
        event.start = currentYear + event.start
        event.commercial = true
        commercialEvents.push(event)
      })
      this.commercialEvents = commercialEvents
    },

    setNearScheduledCampaigns () {
      CampaignService.getScheduled().then((data) => {
        data.campaigns.forEach((campaign) => {
          this.nearScheduledCampaigns.push({
            start: campaign.sending_date,
            name: campaign.name,
            id: campaign.id,
            channel_type_id: campaign.channel_type_id,
            statusGuid: campaign.statusGuid,
            statusName: campaign.statusName,
            percentage_sent: campaign.percentage_sent,
            color: 'blue',
          })
        })
        this.setNearEvents()
      })
    },

    setNearEvents () {
      const actualDate = moment().startOf('day')
      const commercialNowAndFutureEvents = this.commercialEvents.filter(
        (event) => {
          return moment(event.start).startOf('day').isSameOrAfter(actualDate)
        },
      )

      this.nearScheduledCampaigns.forEach((event) => {
        commercialNowAndFutureEvents.forEach(
          (commercialEventNowAndFuture, index) => {
            if (
              moment(commercialEventNowAndFuture.start)
                .startOf('day')
                .isSame(moment(event.start).startOf('day'))
            ) {
              commercialNowAndFutureEvents[index] = event
            }
          },
        )
      })

      this.nearEvents = commercialNowAndFutureEvents.slice(0, 4)
    },

    clickDay (date) {
      this.clickedDay = date
      this.eventPage = 1
      this.eventDayItems = this.getNonCommercialEventsByDate(date)
      this.showDayDialog = true
    },

    campaignsHeaderDateFormat (date) {
      return moment(date).format('DD/MM/YYYY')
    },

    closeDayDialog () {
      this.showDayDialog = false
    },

    getEvents ({ start, end }) {
      const newStart = moment(start.date)
        .subtract(10, 'days')
        .format('YYYY-MM-DD')
      const newEnd = moment(end.date).add(10, 'days').format('YYYY-MM-DD')

      CampaignService.getScheduled({ start: newStart, end: newEnd }).then(
        (data) => {
          const campaignEvents = []
          data.campaigns.forEach((campaign) => {
            campaignEvents.push({
              start: campaign.created_date,
              name: campaign.name,
              id: campaign.id,
              campaignId: campaign.id,
              sendingId: campaign.sendingId,
              channel_type_id: campaign.channel_type_id,
              channelTypeId: campaign.channel_type_id,
              certified: campaign.certified,
              sending_date: campaign.sending_date,
              sending_timezone: campaign.sending_timezone,
              sending_started: campaign.sending_started,
              sending_finished: campaign.sending_finished,
              campaignUpdatedAt: campaign.campaignUpdatedAt,
              total: campaign.total,
              total_recipients: campaign.total_recipients,
              total_processed: campaign.total_processed,
              total_test: campaign.total_test,

              status_id: campaign.status_id,
              statusGuid: campaign.statusGuid,
              statusName: campaign.statusName,
              percentage_sent: campaign.percentage_sent,
              color: 'blue',
            })
          })
          this.calendarEvents = [...this.commercialEvents, ...campaignEvents]
        },
      )
    },

    getEventsByDate (date, maxEvents = null) {
      const all = this.calendarEvents.filter((event) => event.start.includes(date))
      return all.slice(0, maxEvents || all.length)
    },

    getNonCommercialEventsByDate (date, maxEvents = null) {
      const all = this.calendarEvents.filter(event => event.start.includes(date) && !event.commercial)
      return all.slice(0, maxEvents || all.length)
    },

    updateRange () {
      const calendar = this.$refs.calendar
      if (calendar) {
        const start = calendar.getStartOfWeek(calendar.lastStart)
        const end = calendar.getEndOfWeek(calendar.lastEnd)

        this.getEvents({ start, end })
        this.eventDayItems = this.getNonCommercialEventsByDate(this.clickedDay)
      }
    },

    getEventsByDateWithLimit (date, limit) {
      const events = this.getEventsByDate(date)
      return events.splice(0, limit)
    },

    initIntervalCampainDataTable () {
      if (this.refresh) {
        clearInterval(this.refresh)
      }
      this.refresh = setInterval(() => {
        this.setParamsAndGetData()
      }, this.refreshDatatableFrecuency)
    },
    goToEditVerfiedBrand (googleVerifiedBrand) {
      this.$router.push({
        name: 'GoogleVerifiedRequest',
        params: { id: googleVerifiedBrand.id },
      })
    },
    goToCreateCampaignVerifiedSMS (googleVerifiedBrand) {
      this.$router.push({
        name: 'smsCreate',
        params: { verifiedBrandId: googleVerifiedBrand.id },
        query: { type: 'basic' },
      })
    },
    resetReady () {
      this.ready = false
      setTimeout(() => {
        this.ready = true
      })
    },
    addBrand () {
      this.$router.push({ name: 'GoogleVerifiedRequest' })
    },
    setFirstAsSelectedOrPreserveCampaign () {
      const campaign = this.content[0]
      if (!this.selectedCampaign && campaign) {
        this.selectedCampaign = campaign
        this.setCampaingDataSource()
      }
    },
    selectCampaign (selectedCampaign) {
      if (
        selectedCampaign &&
        selectedCampaign.campaignId !== this.selectedCampaign.campaignId
      ) {
        this.selectedCampaign = selectedCampaign
        this.setCampaingDataSource()
      }
    },
    setCampaingDataSource () {
      const params = {
        campaignId: this.selectedCampaign.campaignId,
      }
      switch (this.selectedCampaign.channelTypeId) {
        case 1:
          StatsService.getSmsCampaignDatatable(params).then((response) => {
            this.campaginDataSource = response
            StatsService.getSmsCampaignData(this.selectedCampaign.campaignId)
            .then(
              (response) => {
                this.smsQuickStats.campaign = response.campaign
                this.smsQuickStats.channel = response.channel
                this.smsQuickStats.tags = response.tags
                this.getContactCountriesByCampaign()
              },
              () => {},
            )
            .finally(() => {
              this.resetReady()
            })
          })
          break
        case 2:
          StatsService.getMailingCampaignDatatable(params).then((response) => {
            this.campaginDataSource = response
            StatsService.getMailingCampaignData(this.selectedCampaign.campaignId)
            .then(
              (response) => {
                this.mailingQuickStats.campaign = response.campaign
                this.getContactCountriesByCampaign()
              },
              () => {},
            )
            .finally(() => {
              this.resetReady()
            })
          })
          break

        case 7:
          VoiceStatsService.getCampaignDatatable(params).then((response) => {
            this.campaginDataSource = response
            VoiceStatsService.getCampaignData(this.selectedCampaign.campaignId)
            .then(
              (response) => {
                this.voiceQuickStats.campaign = response.campaign
                this.getContactCountriesByCampaign()
              },
              () => {},
            )
            .finally(() => {
              this.resetReady()
            })
          })
          break
        case 8:
          StatsService.getWhatsappChannelDatatable(params).then((response) => {
            const whatsappGraphicData = new WhatsappGraphicData(response, this)
            this.deliveredRatioGraphic = whatsappGraphicData.getDeliveredRatioGraphicGraphicData()
            this.clicksRatioGraphicData = whatsappGraphicData.getClicksRatioGraphicData()
            StatsService.getWhatsappCampaignData(this.selectedCampaign.campaignId)
            .then(
              (response) => {
                this.whatsappQuickStats.campaign = response.campaign
                this.whatsappQuickStats.channel = response.channel
                this.whatsappQuickStats.tags = response.tags
                this.whatsappQuickStats.senderName = response.senderName
                this.getContactCountriesByCampaign()
              },
              () => {},
            )
            .finally(() => {
              this.resetReady()
            })
            this.getContactCountriesByCampaign()
          })
          break
      }
    },

    getContactCountriesByCampaign () {
      if (this.selectedCampaign && this.selectedCampaign.campaignId) {
        ContactService.getContactCountriesByCampaign({ campaignId: this.selectedCampaign.campaignId })
        .then(
          (response) => {
            this.campaignCountries = response.countriesIso
            if (this.campaignCountries.length > 9) {
              this.campaignCountries = this.campaignCountries.splice(0, 9)
            }
          },
          _ => {
            this.campaignCountries = []
          },
        )
      }
    },

    calculatePercentage (total, current) {
      return (100 * current) / total
    },
    newCampaign (channel, date) {
      if (date) {
        date = date + ' ' + '09:00:00'
        localStorage.setItem('programCampaignDate', date)
      }
      if (channel === 'voice') {
        this.$router.push('campaign/voice/create')
      } else if (channel === 'whatsapp') {
        EventBus.$emit('newWhatsappCampaign', channel)
      } else {
        EventBus.$emit('newCampaign', channel)
      }
    },
    percentageSent (percentage) {
      return percentage <= 100 ? percentage : 100
    },
    dateFormatUTC (date) {
      const utcDate = moment.utc(String(date)).toDate()
      return moment(utcDate).local().format('DD/MM/YYYY HH:mm')
    },
    dateFormat (date) {
      return moment(String(date)).format('DD/MM/YYYY HH:mm')
    },
    goToEdit (item) {
      if (item.status_id === this.campaignStatusValidating) {
        this.toogleHomeModalCampaignValidate()
        this.itemHomeModalCampaignValidate = item
        return
      }

      const campaignId = item.campaignId
      switch (parseInt(item.channelTypeId)) {
        case ChannelType.SMS_ID:
        case ChannelType.MAILING_ID:
        case ChannelType.WHATSAPP_ID:
          this.$router.push(
            '/campaign/' +
              item.slug +
              '/edit/' +
              item.campaignId +
              '?type=' +
              item.campaignSubType,
          )
          break
        case 7:
          this.$router.push('/campaign/voice/edit/' + campaignId)
          break
      }
    },
    toogleHomeModalCampaignValidate () {
      this.showHomeModalCampaignValidate = !this.showHomeModalCampaignValidate
    },
    toggleHomeModalAi () {
      this.showHomeModalAi = !this.showHomeModalAi
    },
    toggleHomeModalBillingProfile () {
      this.showHomeModalBillingProfile = !this.showHomeModalBillingProfile
    },
    editHomeModalCampaignValidate () {
      this.loadingHomeModalCampaignValidate = true
      const data = {
        sendingId: this.itemHomeModalCampaignValidate.sendingId,
        campaginId: this.itemHomeModalCampaignValidate.campaignId,
      }
      CampaignService.updateCampaignMailStatusSave(data)
        .then(
          () => {
            this.loadingHomeModalCampaignValidate = false
            this.$router.push(
              '/campaign/' +
                this.itemHomeModalCampaignValidate.slug +
                '/edit/' +
                this.itemHomeModalCampaignValidate.campaignId +
                '?type=' +
                this.itemHomeModalCampaignValidate.campaignSubType,
            )
          },
          () => {
            EventBus.$emit(
              'showAlert',
              'danger',
              this.$t('Ocurrió un error al validar la campaña'),
            )
          },
        )
        .finally(() => {
          this.loadingWhiteList = false
          this.toogleShowValidateModal()
        })
    },
    goToStats (item) {
      switch (parseInt(item.channelTypeId)) {
        case ChannelType.SMS_ID:
        case ChannelType.MAILING_ID:
          this.$router.push(
            '/stats/' + item.slug + '/campaign/' + item.campaignId,
          )
          break
        case ChannelType.VOICE_INTERACTIVE_ID:
          this.$router.push('/stats/voice/interactive/campaign/' + item.campaignId)
          break
        case ChannelType.VOICE_ID:
          this.$router.push('/stats/voice/basic/campaign/' + item.campaignId)
          break
        case ChannelType.WHATSAPP_ID:
          this.$router.push('/stats/whatsapp/campaign/' + item.campaignId)
          break
      }
    },

    setAction (action, item) {
      EventBus.$emit('toggleAction', { action: action, item: item })
    },
    getDataFromApi (params = {}) {
      this.content = []
      this.loading = true
      this.disabledPagination = true
      CampaignService.getAll(params)
        .then(
          (response) => {
            this.statuses = response.statuses
            this.content = response.data.data
            this.itemsPerPage = parseInt(response.data.per_page)
            this.page = response.data.current_page
            this.totalVisibleCampaigns = response.data.total
            this.totalExistingCampaigns = response.total
            if (this.totalExistingCampaigns === 0) {
              this.loadedFirstTime = true
            } else {
              this.loadedFirstTime = false
            }
            this.setFirstAsSelectedOrPreserveCampaign()
          },
          () => {},
        )
        .finally(() => {
          this.loading = false
          this.disabledPagination = false
          this.updateRange()
          sha256(JSON.stringify(this.content))
          .then(hash => {
            if (this.contentHash !== hash) {
              this.getUserBalance()
              this.contentHash = hash
            }
          })
          this.$nextTick(() => {
            // Flash
            this.doBlinkCampaign()
          })
        })
    },

    getDataFromApiReload (params = {}) {
      this.disabledPagination = true
      CampaignService.getAll(params)
        .then(
          (response) => {
            this.statuses = response.statuses
            this.content = response.data.data
            // this.content[0].dynamicClass = 'blink-less'
            this.itemsPerPage = parseInt(response.data.per_page)
            this.page = response.data.current_page
            this.totalVisibleCampaigns = response.data.total
            this.totalExistingCampaigns = response.total
            if (this.totalExistingCampaigns === 0) {
              this.loadedFirstTime = true
            } else {
              this.loadedFirstTime = false
            }
            this.setFirstAsSelectedOrPreserveCampaign()
          },
          () => {},
        )
        .finally(() => {
          this.loading = false
          this.disabledPagination = false
          this.updateRange()
          sha256(JSON.stringify(this.content))
          .then(hash => {
            if (this.contentHash !== hash) {
              this.getUserBalance()
              this.contentHash = hash
            }
          })
          this.$nextTick(() => {
            // Flash
            this.doBlinkCampaign()
          })
        })
    },

    doBlinkCampaign () {
      if (this.flashCampaign) {
        const itemIdx = this.content.findIndex(c => c.sendingId === this.flashCampaign)
        const item = { ...this.content[itemIdx] }
        item.dynamicClass = 'blink-less'
        this.$set(this.content, itemIdx, item)
        setTimeout(() => {
          delete item.dynamicClass
          this.$set(this.items, itemIdx, item)
        }, 2000)
        this.flashCampaign = null
      }
    },

    filterByChannelTypeId (channelTypeId, index) {
      if (channelTypeId === this.channelFilterSelected.id) return

      this.page = this.options.page = 1
      this.channelFilterSelected = this.channelsFilter[index]
      this.setParamsAndGetData()
    },

    // eslint-disable-next-line complexity
    setParamsAndGetData () {
      const page = this.tableAction === 'DELETED' && this.content.length === 1 && this.options.page > 1
        ? this.options.page - 1
        : this.options.page
      this.tableAction = null
      const params = {
        page: page,
        perPage: this.options.itemsPerPage,
        searchTerm: this.searchTerm,
        channelTypeId:
          this.channelFilterSelected.id === -1
            ? ''
            : this.channelFilterSelected.channels,
      }

      if (this.options.sortBy) {
        for (let i = 0; i < this.options.sortBy.length; i++) {
          params['sortBy[' + i + '][field]'] = this.options.sortBy[i]
          params['sortBy[' + i + '][dir]'] = this.options.sortDesc[i]
            ? 'asc'
            : 'desc'
        }
      }

      if (this.reload) {
        this.getDataFromApiReload(params)
      } else {
        this.getDataFromApi(params)
      }
    },
    loadNews () {
      const user = JSON.parse(localStorage.getItem('user'))
      RssFeedService.getRssNewsFeed({ token: user.token }).then((response) => {
        this.newsFeed = response
      })
    },
    getBilling () {
      AccountService.getBilling().then((response) => {
        this.billing = new Billing(response.billingProfiles)
        this.showPendingFiscalProfile =
          this.billing.billingProfiles.length === 0 ||
          this.checkAnyFiscalProfileValid()
      })
    },
    checkAnyFiscalProfileValid () {
      var result = this.billing.billingProfiles.find((obj) => {
        return obj.is_valid === 1
      })
      return result === undefined
    },
    setBalanceAlert () {
      AccountService.getBalance().then((data) => {
        this.balanceAlert = data
      })
    },
    loadGoogleVerifiedBrands () {
      VerifiedSmsService.getVerifiedBrands().then((response) => {
        this.googleVerified = response
        if (this.googleVerified.length > 4) {
          this.googleVerified = this.googleVerified.splice(0, 4)
        }
      })
    },
    async getUserBalance () {
      try {
        await this.$store.dispatch('balance/getBalance')
      } catch (error) {
        console.error(error)
      }
    },
    activeGoogleVerifiedAvailable () {
      if (this.isGoogleVerifiedAvailable) {
        return 'col-md-4'
      } else {
        return 'col-md-8'
      }
    },
  },
  beforeDestroy () {
    const htmlElement = this.getMainHtmlElement()
    htmlElement.style.overflow = 'auto'
  },
 }
